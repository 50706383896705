<template>
  <div class="w_auto" style="min-width:1200px">
    <!-- <BreadcrumbNav></BreadcrumbNav> -->
    <div class="bgwhites" style="margin-top: 20px">
      <div class="btn-color">
        <!-- <span>排序</span> -->
        <!-- <el-tabs v-model="curTab" @tab-click="tabClick">
          <el-tab-pane v-for="(tab,index) in stateTab" :key="index" :label="tab.label" :name="tab.value" />
        </el-tabs> -->
        <!-- <el-divider direction="vertical"></el-divider> -->
        <ul class="courseul">
          <li :class="{actives:(newIndex==0)}" @click="menuClick(0,'')">全部</li>
          <li
          class=""
          v-for="(item,index) in courseList" 
          :key="index" 
          :class="{actives:(newIndex==(index+1))}"
          @click="menuClick(index+1,item.labelId)"
          >
          {{item.labelName}}
          </li>
        </ul>
        <el-divider direction="vertical"></el-divider>
        <el-cascader
          class="kc-input"
          placeholder="请选择课程"
          :props="defaultParams"
          :key="cascaderKey"
          v-model="input"
          :options="options"
          @change="handleChange">
        </el-cascader>
      </div>
    </div>
    <div class="mt15">
      <div>
        <el-row :gutter="isPc ? 20 : 0">         
          <el-col :span="isPc ? 18 : 24">
            <div class="bagray bagrayns" >
            <el-row :gutter="20" v-if="trainingList.length!=0">
              <el-col :span="isPc ? 8 : 24" v-for="(i, index) in trainingList" :key="index" class="mt10">
                <el-card :body-style="{ padding: '0px' }" class="hovershadow is-always-shadow">
                  <div class="indpic"  v-if="i.curriculumPic1">
                    <img :src="i.curriculumPic1" class="image" @click="subclick(i.curriculumResourceId,i.experimentId)"/>
                    <!-- <div class="kcstat flex-betweens">
                      <span
                        ><i class="el-icon-video-camera-solid"></i
                        >{{i.courseState==0?'未开始':i.courseState==1?"进行中":'已结束'}}</span
                      >
                    </div> -->
                  </div>
                   <div class="indpic" v-else>
                    <img :src="onlineImg" class="image" @click="subclick(i.curriculumResourceId,i.experimentId)"/>
                    <!-- <div class="kcstat flex-betweens">
                      <span
                        ><i class="el-icon-video-camera-solid"></i
                        >{{i.courseState==0?'未开始':i.courseState==1?"进行中":'已结束'}}</span
                      >
                    </div> -->
                  </div>
                  <div style="padding: 14px">
                    <span class="bt-title" @click="subclick(i.curriculumResourceId,i.experimentId)">课程名称：{{ i.curriculumTheme }}</span>
                    <span class="bt-title" @click="subclick(i.curriculumResourceId,i.experimentId)">实验名称：{{ i.experimentName }}</span>
                    <div class="bottom clearfix">
                      <p>
                        <!-- <span>{{i.chapterNum}}节</span>
                        <el-divider direction="vertical"></el-divider>
                        <span>{{i.payNum}}人加入</span>
                        <el-divider direction="vertical"></el-divider> -->
                        <span>讲师：{{i.teacherName}}</span>
                      </p>
                      <div class="isfree flex-betweens">
                        <!-- <span v-if="i.joinWay==3">￥{{i.salePrice}}</span>
                        <el-button type="warning" size="mini" v-if="i.joinWay==3" @click="subclick(i.courseId)">购买</el-button>
                        <el-button type="warning" size="mini" v-else-if="i.joinWay==1" style="margin-left:80%" @click="subclick(i.courseId)">口令</el-button>       
                        <el-button type="warning" size="mini" v-else-if="i.joinWay==0" style="margin-left:80%" @click="subclick(i.courseId)">免费</el-button>      
                        <el-button type="warning" size="mini" v-else style="margin-left:80%" @click="subclick(i.courseId)">指定</el-button>                        -->
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <div v-else>
            <el-empty :image-size="200"></el-empty>
          </div>
          </div>         
         <div class="page pagemb">
            <el-pagination
              background
              class="pagination"
              layout="prev, pager, next"
              :page-size="pageSize"
              :total="count"
              :current-page="page"
              @current-change="handleCurrentChange"
              >
            </el-pagination>
          </div>
          </el-col>                 
          <el-col :span="isPc ? 6 : 24">   
            <div class="new-card">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <i class="el-icon-caret-right"></i>
                  <span>新闻资讯</span>
                </div>
                <div>
                    <!-- <div style="display:flex;border-bottom: 1px dashed #ccc;padding: 10px;" v-for="(item,index) in xwzxList" :key="index">
                      <img style="width:50%" :src="item.img" alt="">
                      <div style="width:40%;margin-left:3%;padding-top:10px">
                        <p style="color:#333;font-size:14px">{{item.title}}</p>                        
                        <div style="padding-top:30px" class="cktj">
                          <div style="color:#666;padding-bottom:10px">{{item.teacher}}</div>
                          <i class="el-icon-user"></i>
                          <span>{{item.jojn}}人参加</span>
                        </div>
                      </div>
                    </div> -->
                  <el-empty v-if="true" :image-size="150" description="暂无推荐" />
                </div>
              </el-card>  
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
      
  </div>
</template>

<script>
import { length } from '@/assets/timer';
// import BreadcrumbNav from '@/components/breadCrumbs/Breadcrumb'
export default {
  // components:{ BreadcrumbNav },
  data(){
    return{
      token:localStorage.getItem("pc_token"),
      curriculumOrExperimentName:this.$route.query.curriculumOrExperimentName??'',
      input:'',
      cascaderKey:1,
      onlineImg:require("@/assets/images/onlinecourses/kecheng2.jpg"),
      defaultParams: {
        // label: "typeName",
        // value: "typeId",
        lazy: true,
        lazyLoad : this.cascaderLazyLoad,
      },
       options: [
       
        ],
        trainingList:[],
        xwzxList:[
          {img:require("@/assets/images/onlinecourses/cktj.jpg"),title:'互联网计算',teacher:'田老师',jojn:2},
          {img:require("@/assets/images/onlinecourses/cktj.jpg"),title:'互联网计算',teacher:'李老师',jojn:2},
          {img:require("@/assets/images/onlinecourses/cktj.jpg"),title:'互联网计算',teacher:'汪老师',jojn:4},
        ],
        stateTab: [
        {
          "label": "全部",
          "value": "all"
        }, {
          "label": "免费",
          "value": "0"
        }, {
          "label": "收费",
          "value": "1"
        }
        ],
        courseType:'',
        courseList:[],
        curTab: 'all',
        newIndex:0,
        labelIdName:'',
        pageSize:9,
        page: 1,
        count: 0,
    }
  },
  mounted(){
    // this.getFrontLabelPageApi()
    this.getCourseListApi()
  },
  computed: {
    isPc() {
			return window.screen.width <= 767 ? false : true;
		},
  },
  watch: {
    // 监听路由是否变化
    '$route' (to, from) { 
      if(to.query.curriculumOrExperimentName != this.curriculumOrExperimentName){
        this.curriculumOrExperimentName = to.query.curriculumOrExperimentName; // 把最新id赋值给定义在data中的id
        this.getCourseListApi(); // 重新调用加载数据方法
      }
    }
  },
  methods:{
    cascaderLazyLoad(node, resolve){
      if (!node) {  
           return false
       }
       const level = node.level;
       console.log(node);
        // 请求参数
        const requestData = {
            "channelId": this.$http.channelId
        };
          // 一级接口
          this.$api.getClientCurriculumResourceListApi(requestData).then(resonse => {
            // 返回节点数据
            if(resonse.code == 10000){
              // resolve(resonse.data)
              resolve(
                resonse.data.map((val) => {
                  return {
                    value: val.curriculumResourceId,
                    label: val.curriculumTheme,
                    leaf: true,
                  }
                })
              )
              
            }
            
          })
    },
    // 标签列表
    getFrontLabelPageApi(){
      this.$api.getFrontLabelPageApi({
        channelId: this.$http.channelId,
      }).then(res => {
        if(res.code == 10000){
          this.courseList = res.data
        }
      })
    },
    getCourseListApi(){
      if(this.courseType == "all"){
        this.curriculumResourceId = ""
      }
      this.$api.getClientCurriculumExperimentListApi({
        channelId: this.$http.channelId,
        page: this.page,
        limit: this.pageSize,
        typeOne:'',
        typeTwo:'',
        typeThree:'',
        curriculumResourceId: this.courseType,
        labelId: this.labelIdName,
        curriculumOrExperimentName: this.curriculumOrExperimentName,
        token:this.token
      }).then(res => {
        if(res.code == 10000){
          this.trainingList = res.data.data
          this.count = res.data.count
        }
      })
    },
    // 分页
    handleCurrentChange(curPage){
      this.page = curPage
      this.getCourseListApi()
    },
    menuClick(index,labelId){
      this.$router.push({
            path: "/course/index",
            query:{
            }
      });
      this.newIndex = index;
      this.courseType =''
      this.page = 1
      this.input=''
      this.$store.dispatch('setCourseName','')
      this.curriculumOrExperimentName = ''
      this.labelIdName = labelId
      this.getCourseListApi()
    },
    
    tabClick(tab){
      let type = tab.name
      this.courseType = type
      if(type == "all"){
        this.page = 1
        this.getCourseListApi()
      }else{
        this.getCourseListApi()
      }
    },
    handleChange(value) {
        this.courseType = value[0]
        this.newIndex = ''
        this.page = 1
        this.getCourseListApi()
    },
    subclick(id,experimentId){
        this.$router.push({
          path:'/course/detailList',
          query:{
            id:id,
            experimentId:experimentId
          }
        })
    }
  }
  
}
</script>

<style scoped lang="scss">
.bgwhites{
  background-color: #fff;
  padding: 16px;
  .btn-color{
    display: flex;
    align-items: center;   
    font-size: 14px;    
  }
  .btn-color span{
    padding-right:15px;
  }
}
.actives {
  color: #18cca7;
}
.mt15 {
  margin-top: 15px;
  .bagray{
    padding: 10px 20px 15px; 
    background-color: #fafafa; 
    // border-radius: 5px;
    box-shadow: 0px 8px 10px -3px rgb(0 0 0 / 10%) 
  }
  .bagrayns{
    box-shadow:none
  }
  .indpic {
    position: relative;
    .image {
      width: 100%;
      height: 1.07rem;
      display: block;
      cursor: pointer;
    }
    .kcstat {
      position: absolute;
      width: 100%;
      padding: 5px;
      bottom: 0;
      color: #fff;
      background: rgba(0, 0, 0, 0.2);
      font-size: 12px;
    }
    .kcstat i {
      font-size: 20px;
      color: #f00;
      margin-right: 5px;
    }   
  }
  .bottom {
    margin-top: 3px;
    line-height: 12px;
  }
  .bottom p {
    font-size: 12px;
    color: #ccc;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
  .isfree {
    margin-top: 10px;
  }
  .isfree span {
    color: #f00;
  }
  .isfree button {
    padding: 7px 10px;
    background: linear-gradient(#f47349 0%, #f0955d 70%);
    border-radius: 3px;
    color: #fff;
  }
  .flex-betweens{
      display: flex;
      justify-content : space-between;
      align-items: center; 
      align-content: center;
    }
  }
.pagination{
  display: flex;
  justify-content: flex-end;
  margin: 3% 0;
}
.pagemb{
 margin-bottom: 1%;
}
    
// .pagination {
//   margin: 3rem auto;
//   display: flex;
//   justify-content: center;
// }
.hovershadow:hover {
    box-shadow: 0 0 5px 0 #c7c7c7, 0 1px 8px 0 #c7c7c7;
    transform: translateY(-3px);
  }
  .is-always-shadow {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
.bt-title{
  width: 270px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  color: #333;
  font-size: 13px;
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
}
.bt-title:hover{
  color: #3ce4bc;
}
.cktj{
  color:#000;
  font-size:12px
}
.courseul{
  display: flex;
}
.courseul li{
  padding: 0 15px;
  cursor: pointer;
}
.page {
  display: inline-block;
  width: 100%;
  text-align: right;
}
.mb20{
  margin-bottom: 5%;
}
.btn-color ::v-deep .el-button--primary{
  background:linear-gradient(#3ce4bc 0%, #68b9cd 70%) !important;
}
.kc-input{
  width: 17%;
}
.clearfix ::v-deep .el-icon-caret-right {
  font-size: 25px;
}
.new-card::v-deep .el-card__header{
  padding: 10px 20px 15px; 
}
.new-card::v-deep .el-card__body {
  padding: 1px;
}
// 排序开始
.btn-color::v-deep .el-tabs__nav-wrap::after{
  height: 0px;
}
.btn-color::v-deep .el-tabs__active-bar{
  height: 0px;
}
.btn-color::v-deep .el-tabs__header{
  margin: 0 0 1px
}
.btn-color::v-deep .el-tabs__item{
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .w_auto {
    width: 100% !important;
    min-width: 100% !important;
    .courseul {
      flex: 0 0 16vw;
    }
    .kc-input {
      width: 100%;
    }
  }
}
</style>